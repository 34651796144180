export function formatDate(
	date: string | number | Date,
	locale: string,
	options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' }
): string {
	return new Intl.DateTimeFormat(locale, options).format(new Date(date));
}

export function safeDate(input: any): [Date, null] | [null, Error] {
	const date = new Date(input);
	if (isNaN(date as any)) return [null, new Error(`${input} is not a valid date`)];
	return [date, null];
}

export function timeAgo(date: Date): string {
	const now = new Date();
	const delta = Math.floor((now.getTime() - date.getTime()) / 1000);

	if (delta < 60) {
		return `${delta} seconds ago`;
	} else if (delta < 3600) {
		const minutes = Math.floor(delta / 60);
		return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
	} else if (delta < 86400) {
		const hours = Math.floor(delta / 3600);
		return `${hours} hour${hours > 1 ? 's' : ''} ago`;
	} else if (delta < 2592000) {
		// Roughly 30 days
		const days = Math.floor(delta / 86400);
		return `${days} day${days > 1 ? 's' : ''} ago`;
	} else if (delta < 31536000) {
		// Roughly 365 days
		const months = Math.floor(delta / 2592000);
		return `${months} month${months > 1 ? 's' : ''} ago`;
	}
	const years = Math.floor(delta / 31536000);
	return `${years} year${years > 1 ? 's' : ''} ago`;
}
