<script lang="ts">
	import mermaid, { type MermaidConfig } from 'mermaid';
	import { theme } from '$internal/stores/theme';
	import { fallbackLightKey } from '$const/theme';
	import { fade } from 'svelte/transition';
	import { browser } from '$app/environment';

	export let id: string;
	export let code: string;
	export let withoutMaxWidth = true;

	let ref: HTMLDivElement;

	async function renderDiagram(config: MermaidConfig): Promise<void> {
		mermaid.initialize(config);
		const { svg } = await mermaid.render(id, code);

		if (withoutMaxWidth) {
			const styleStart = svg.indexOf('style="');
			const styleStop = svg.indexOf('"', styleStart);
			ref.innerHTML = svg.substring(0, styleStart) + svg.substring(styleStop);
			return;
		}

		ref.innerHTML = svg;
	}

	$: browser && code && renderDiagram({ theme: $theme === fallbackLightKey ? 'neutral' : 'dark' });
</script>

<div in:fade bind:this={ref} class={$$props.class ?? ''} />
